import {
  InputLabel, Select, MenuItem, SelectChangeEvent,
} from '@mui/material';
import { Team } from 'lib/types/teams';

interface Props {
  teams: Team[];
  label?: string;
  selected?: number;
  onChange: (teamId?: number) => void;
}

export const TeamSelect = ({
  teams, selected, label, onChange,
}: Props) => {
  const selectTeam = (e: SelectChangeEvent<string>) => {
    const teamId = e.target.value;
    if (!teamId) {
      onChange(undefined);
      return;
    }

    onChange(Number(teamId));
  };

  return (
    <div className="mb-4">
      <InputLabel id="team-label" className="font-semibold">{label || 'Team'}</InputLabel>
      <Select
        labelId="team-label"
        id="team-select"
        name="team-select"
        value={selected?.toString() || ''}
        onChange={selectTeam}
        size="small"
        fullWidth
      >
        <MenuItem value="">---</MenuItem>
        {teams.map((team) => (
          <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
};
