import {
  InputLabel, Select, MenuItem, SelectChangeEvent,
} from '@mui/material';
import { List } from 'lib/types';

interface Props {
  lists: List[];
  label?: string;
  selected?: number;
  onChange: (listId?: number) => void;
}

export const ListSelect = ({
  lists, selected, label, onChange,
}: Props) => {
  const selectList = (e: SelectChangeEvent<string>) => {
    const listId = e.target.value;
    if (!listId) {
      onChange(undefined);
      return;
    }

    onChange(Number(listId));
  };

  if (!lists) return null;

  return (
    <div className="mb-4">
      <InputLabel id="team-label" className="font-semibold">{label || 'List'}</InputLabel>
      <Select
        labelId="list-label"
        fullWidth
        id="list-select"
        name="list-select"
        onChange={selectList}
        size="small"
        value={selected?.toString() || ''}
      >
        <MenuItem value="">---</MenuItem>
        {lists.map((list) => (
          <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
        ))}
      </Select>
    </div>
  );
};
