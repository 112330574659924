import React from 'react';
import { useRouter } from 'next/router';
import { useModal } from 'mui-modal-provider';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { trackClickUpgradeEvent } from 'lib/hooks/useEventTracking';
import { useApi } from 'lib/contexts/ApplicationState';

interface Props {
  text: string;
  onCancel: () => void;
}

export const UpgradeModal = ({
  onCancel, text,
}: Props) => {
  const router = useRouter();
  const flexEmoji = String.fromCodePoint(0x1F4AA);

  const goToPlans = () => {
    router.push({ pathname: '/plans', query: { returnTo: router.pathname } });
  };

  return (
    <Dialog open>
      <div className="p-6">
        <h1 className="text-xl font-semibold mb-4">
          PRO feature
        </h1>
        <div className="absolute right-1 top-1">
          <IconButton
            aria-label="close"
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mb-4 text-lg">
          {text}
        </div>
        <div className="text-center">
          <div className="mb-2">Start your free 2-week trial</div>
          <button type="button" className="btn-primary text-xl" onClick={goToPlans}>
            Let&apos;s go PRO&nbsp;
            {flexEmoji}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export const TeamsUpgradeModal = ({
  onCancel, text,
}: Props) => {
  const router = useRouter();
  const flexEmoji = String.fromCodePoint(129309);

  const goToTeams = () => {
    router.push({ pathname: '/plans/teams', query: { returnTo: router.pathname } });
  };

  return (
    <Dialog open>
      <div className="p-4">
        <h1 className="text-xl font-semibold mb-4">
          Teams feature
        </h1>
        <div className="absolute right-2 top-2">
          <IconButton
            aria-label="close"
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className="mb-4">
          {text}
        </div>
        <div className="text-center">
          <div className="mb-2">
            {flexEmoji}
            &nbsp;Let&apos;s work together&nbsp;
            {flexEmoji}
          </div>
          <button type="button" className="btn-primary text-xl" onClick={goToTeams}>
            Request a demo
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export const useTeamsUpgradeModal = () => {
  const { showModal } = useModal();
  const API = useApi();

  const showTeamsUpgradeModal = async (text?: string) => {
    await trackClickUpgradeEvent(API, { text, upgradeType: 'Teams' });

    const modal = showModal(TeamsUpgradeModal, {
      text: text || 'Upgrade to Gondola Teams',
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  return { showTeamsUpgradeModal };
};

export const useUpgradeModal = (proUpgrade = true) => {
  const { showModal } = useModal();
  const API = useApi();

  const showUpgradeModal = async (text?: string) => {
    await trackClickUpgradeEvent(API, { text, upgradeType: proUpgrade ? 'Pro' : 'Teams' });

    const modal = proUpgrade ? showModal(UpgradeModal, {
      text: text || 'Upgrade to Gondola PRO to import posts',
      onCancel: () => {
        modal.destroy();
      },
    }) : showModal(TeamsUpgradeModal, {
      text: text || 'Upgrade to Gondola Teams',
      onCancel: () => {
        modal.destroy();
      },
    });
  };

  return { showUpgradeModal };
};
